import { Injectable, inject } from '@angular/core';
import { TranslationService } from '../../shared/translation/translation.service';

export enum ToastMessageType {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  COOKIE = 'cookie',
}

export interface ToastMessage {
  type: ToastMessageType;
  content: any;
  hasComponent: boolean;
  isExpirable: boolean;
  isClicked: boolean;
  callback: any;
}

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  private _translate = inject(TranslationService);


  /**
   * Time before message deletes
   */
  private _expirationTime = 5 * 1000;

  /**
   * List of visible messages
   */
  private _messagesList: Array<ToastMessage> = [];

  get messagesList(): Array<ToastMessage> {
    return this._messagesList;
  }

  /**
   * Show ERROR toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public error(content, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.ERROR, content, translateParams, hasComponent, isExpirable);
  }

  /**
   * Show INFO toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public info(content, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.INFO, content, translateParams, hasComponent, isExpirable);
  }

  /**
   * Show WARNING toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public warning(content, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.WARNING, content, translateParams, hasComponent, isExpirable);
  }

  /**
   * Show SUCCESS toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public success(content, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.SUCCESS, content, translateParams, hasComponent, isExpirable);
  }

  public cookie(content, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.COOKIE, content, translateParams, hasComponent, isExpirable);
  }

  /**
   * Showing toast message
   *
   * @param type
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   * @private
   */
  private _showMessage(type: ToastMessageType, content, translateParams, hasComponent, isExpirable) {
    const message: ToastMessage = {
      type,
      content: this._setContent(content, translateParams),
      hasComponent,
      isExpirable,
      isClicked: false,
      callback: () => {}
    };

    this._messagesList.push(message);

    /**
     * Removing created message after time expires
     */
    if (isExpirable) {
      setTimeout(() => {
        this.removeMessage(message);
      }, this._expirationTime);
    }

    return message;
  }

  /**
   * Sets content for toast message
   */
  private _setContent(content, translateParams) {
    if (typeof content === 'string') {
      return this._translate.translate(content, translateParams);
    } else {
      return content;
    }
  }

  public removeMessage(message) {
    message.callback();
    const index = this.messagesList.indexOf(message);
    if (index !== -1) {
      this._messagesList.splice(index, 1);
    }
  }
}
