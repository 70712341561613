<app-form-input class="input-item" [control]="control()">
  <label class="checkbox" [ngStyle]="styles()">
    <input type="checkbox" (change)="change($event)" [checked]="checked()">
    <span class="checkbox__view" [style.border-color]="borderColor()">
      <i class="icon-check-checkbox"></i>
    </span>
    <span class="checkbox__label">
      @if (label) {
        <span [innerHTML]="label | safe: 'html'"></span>
      } @else {
        <ng-template [ngTemplateOutlet]="labelTpl()"></ng-template>
      }
    </span>
  </label>
</app-form-input>

