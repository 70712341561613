import { Component, input } from '@angular/core';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],

})
export class PreloaderComponent {

  readonly color = input('#fff');

  constructor() { }
}
