import {Component, Input, TemplateRef, input, output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import { FormInputComponent } from '../form-input/form-input.component';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { SafePipe } from '../../../shared/pipes/safe.pipe';

@Component({
    selector: 'app-form-checkbox',
    templateUrl: './form-checkbox.component.html',
    styleUrls: ['./form-checkbox.component.scss'],
    imports: [
        FormInputComponent,
        NgStyle,
        NgTemplateOutlet,
        SafePipe
    ]
})
export class FormCheckboxComponent {
  readonly checked = input<boolean>(undefined);
  @Input() label: string;
  readonly labelTpl = input<TemplateRef<any>>(undefined);
  readonly borderColor = input<string>('');
  readonly control = input<AbstractControl>(new FormControl());
  readonly styles = input<{
    [index: string]: string;
}>(undefined);
  readonly change$ = output<Event>();

  constructor() {}

  public change($event) {
    this.control()?.setValue($event?.target?.checked);
    this.change$.emit($event);
  }
}
