import { Directive, ElementRef, HostListener, inject, input, output } from '@angular/core';

export interface ClickOutsideOptions {
  /**
   * clickOutside event will not be emitted if clicked element inside one of elements with provided selectors
   */
  ignoreOutsideComponents: Array<string>;
}

@Directive({
    selector: '[ClickOutside]',

})
export class ClickOutsideDirective {
  private _elementRef = inject(ElementRef);


  readonly options = input<ClickOutsideOptions>({
    ignoreOutsideComponents: []
});

  readonly clickOutside = output();
  readonly clickInside = output();

  @HostListener('document:mousedown', ['$event.target']) clickedOutside(target) {
    if (this._isClickedInside(target)) {
      this.clickInside.emit();
    } else {
      this.clickOutside.emit();
    }
  }

  /**
   * Check if a click was inside the element
   *
   * @param target
   * @private
   */
  private _isClickedInside(target: HTMLElement): boolean {
    if (this._elementRef?.nativeElement?.innerHTML?.includes(target?.outerHTML)) {
      return true;
    } else {
      return this._isIgnoreOutsideElementsClicked(target);
    }
  }

  /**
   * Check if clicked element inside ignored elements
   *
   * @param target
   * @private
   */
  private _isIgnoreOutsideElementsClicked(target: HTMLElement): boolean {
    return this.options().ignoreOutsideComponents.some((selector) => !target.closest(selector));
  }

}
